import React, { useEffect } from "react";
import PageWrapper from "../components/PageWrapper";
import SEO from "../components/Layout/SEO";

const SignIn = () => {
  useEffect(() => {
    window.location.replace(`${process.env.DASHBOARD_SIGNIN_URL}`);
  }, []);

  return (
    <>
      <PageWrapper>
        <SEO
          title="Log In to Your Camelo Account"
          description="Simply enter your email address here and don't forget your own
            password, and then you are ready to sign in/log in to your Camelo
            account."
        ></SEO>
      </PageWrapper>
    </>
  );
};
export default SignIn;
